//INFO: 프로젝트 일괄 등록 page

<script>
import { GridComponent, ColumnsDirective, Sort, Toolbar, Search, Page } from '@syncfusion/ej2-vue-grids'
import { searchPjt, getTotalPjtData } from '@/api/admin/ntis/ntis' // NTIS API
import { getLab } from '@/api/admin/labs.js'
import { ref } from 'vue'
import { searchSave } from '@/api/admin/ntis/ntisSearchSave'
const isLoading = ref(false)

export default {
  name: 'DataDetail',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective
  },
  provide: {
    grid: [Sort, Toolbar, Search, Page]
  },
  data() {
    return {
      isLoading: false,

      labNo: '',
      labName: '',
      leadResearcherName: '',
      labAffiliatedInstitutionName: '',

      // ***** 그리드 세팅 *****
      selectionOptions: { type: 'Multiple', enableSimpleMultiRowSelection: true },
      // selectionOptions: { checkboxMode: 'ResetOnRowClick' },
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbarOptions: ['Search'], // 툴바에 검색 옵션
      dataGrid: '',

      // ***** 검색 *****
      searchedProjectData: [], // 프로젝트 검색 결과
      setInitialPjt: [],
      totalProjectData: {}, // 검색 결과 데이터(100건)
      keyword1: '', // 검색어 SRWR
      keyword2: '', // 검색어 SRWR
      keyword3: '', // 검색어 SRWR
      select1: 'AU01=', // 검색 필드 searchFd 책임연구자
      select2: '%26TI01=', // 검색 필드 searchFd 과제명
      select3: '%26PB01=', // 검색 필드 searchFd 수행기관
      pjt_num: '',
      projectManager: '',
      projectTitleKr: '',
      projectLeadAgency: '',

      // ***** 저장할 체크박스 데이터 *****
      chkList: [],
      chkArr: [],
      totalChkList: ''
    }
  },
  mounted() {
    this.totalChkList = []
    const getThisLab = async () => {
      isLoading.value = true

      const data = await getLab(Number(this.$route.params.id))

      this.labNo = data.labNo
      this.labName = data.labName
      this.labAffiliatedInstitutionName = data.affiliatedInstitutionName
      this.leadResearcherName = data.leadResearcherName

      this.projectManager = data.leadResearcherName
      this.projectLeadAgency = data.affiliatedInstitutionName

      isLoading.value = false
    }
    getThisLab()
  },
  methods: {
    // ***** 목록 이동 *****
    goBack() {
      this.$router.push({
        path: '/admin/data'
      })
    },

    // ***** 과제고유번호로 과제 검색 *****
    async projectNumberSearch() {
      const result = await searchPjt(this.pjt_num)

      this.setInitialPjt.length = 0
      this.setInitialPjt.push(result)
      this.searchedProjectData = this.setInitialPjt
    },

    // ***** 프로젝트 검색 *****
    async projectSearchResult() {
      // 검색어, 검색 필드에 값 넣어주기
      // 책임 연구자 설정
      if (this.projectManager) {
        this.keyword1 = this.projectManager
      } else {
        this.keyword1 = ''
      }

      // 과제명 설정
      if (this.projectTitleKr) {
        this.keyword2 = this.projectTitleKr
      } else {
        this.keyword2 = ''
      }

      // 수행기관 설정
      if (this.projectLeadAgency) {
        this.keyword3 = this.projectLeadAgency
      } else {
        this.keyword3 = ''
      }

      this.isLoading = true

      if (!this.keyword1 && !this.keyword2 && !this.keyword3) {
        alert('검색어를 입력해주세요')
      } else {
        // 프로젝트 검색 함수 호출 -> 검색 결과 totalProjectData에 담기
        await getTotalPjtData(1, this.keyword1, this.keyword2, this.keyword3, this.select1, this.select2, this.select3).then((gridData) => {
          this.totalProjectData = gridData
          this.keyword1 = ''
          this.keyword2 = ''
          this.keyword3 = ''
        })

        this.searchedProjectData = this.totalProjectData.LIST
        this.dataGrid = this.$refs.grid.ej2Instances
      }

      this.isLoading = false
    },

    // TODO: 선택한 프로젝트 checkbox 체크할 때마다 빈 LIST에 모아두고 저장하기.
    // ***** 일괄 저장 (프로젝트, 논문, 지재권) *****
    async saveSelected() {
      let result = 0
      for (let i = 0; i < this.totalChkList.length; i++) {
        // 선택한 체크박스의 과제고유번호
        const pjtNum = this.totalChkList[i]

        // 프로젝트, 논문, 지재권 일괄 저장 함수 호출
        result = await searchSave(pjtNum, this.labNo)
      }
      if (result === 1) {
        alert('프로젝트가 등록되었습니다.')
      } else {
        alert('프로젝트 등록이 실패했습니다.')
      }
    },

    // ***** 체크박스 클릭 이벤트 *****
    dataRowSelected(e) {
      // row 의 length가 있으면 전체 체크 OR 없으면 단행 체크
      if (e.row.length) {
        // this.chkList = []
        // 반복문 돌면서 과제 고유번호만 chkList 에 담기
        for (let i = 0; i < e.row.length; i++) {
          this.chkList.push(e.row[i].childNodes[2].innerHTML)
        }
      } else {
        // 전체 체크 시 데이터가 들어있으니 비워주기
        this.chkList = []

        // 클릭된 행 자체를 chkArr에 담기
        this.chkArr.push(e)

        // 클릭된 행들 배열에서 과제 고유번호만 빼서 chkList 에 담기
        for (let j = 0; j < this.chkArr.length; j++) {
          this.chkList.push(this.chkArr[j].data.projectNumber)
        }
      }

      // chkList의 중복 방지 => 최종 체크 배열 totalChkList
      this.totalChkList = []
      const chkListSet = new Set(this.chkList)
      this.totalChkList = [...chkListSet]
    },

    // ***** 체크박스 UN클릭 이벤트 *****
    rowDeselected(e) {
      // row 의 length가 있으면 전체 체크 OR 없으면 단행 체크
      if (e.data.length === 10) {
        if (e.isHeaderCheckboxClicked === false) {
        }
        // 최종 체크 배열 totalChkList에 전체 체크 해제한 아이템들이 들어있으면 빼준다.
        if (e.isHeaderCheckboxClicked === true) {
          this.totalChkList = e.data.filter((item) => this.totalChkList.includes(item))
        }
      } else {
        // uncheck 한 과제 고유번호 제거해서 totalChkList 새로 리턴
        this.totalChkList = this.totalChkList.filter((item) => item !== e.data.projectNumber)
        this.chkArr = this.chkArr.filter((item) => item.data.projectNumber !== e.data.projectNumber)
      }
    },

    // ***** 페이지 이동 이벤트 *****
    actionBegin(e) {
      const interval = setInterval(() => {
        if (e.rows) {
          clearInterval(interval)
          // 체크박스 태그
          const checkedRows = document.querySelectorAll('.e-checkbox-wrapper > .e-frame.e-icons')
          // 최종 체크 배열이 있으면(페이지 이동 전 체크한 값들이 있으면)
          if (this.totalChkList) {
            // 페이지 이동했을 때 그리드의 로우 수만큼 반복
            for (let i = 0; i < e.rows.length; i++) {
              // 체크박스 배열안에 >  현재 페이지 그리드의 과제고유번호 포함되었다면
              if (JSON.stringify(this.totalChkList).includes(e.rows[i].data.projectNumber)) {
                // 현재 페이지의 체크박스에 체크해주기
                checkedRows[i + 1].classList.add('e-check')
              }
            }
          }
        }
      }, 100)
    },

    // ***** 페이지 이동 후 이벤트 *****
    actionComplete(e) {
      for (const row of e.rows) {
        const checkRow = this.totalChkList.find((item) => item === row.data.projectNumber)
        if (checkRow) this.$refs.grid.selectRow(checkRow.rowIndex)
      }
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <p>연구실 프로젝트를 일괄 등록하는 화면입니다.</p>
  <!-- 검색 구역 시작 -->
  <div id="searchArea">
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="20%" />
          <col width="80%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 정보</th>
            <td class="lab_info_area">
              <input type="text" name="labNo" v-model="labNo" disabled /> /
              <input type="text" name="labName" v-model="labName" disabled /> /
              <input type="text" name="leadResearcherName" v-model="leadResearcherName" disabled /> /
              <input type="text" name="labAffiliatedInstitutionName" v-model="labAffiliatedInstitutionName" disabled />
            </td>
          </tr>
          <tr>
            <th>과제고유번호</th>
            <td>
              <label class="input-wrapper input_pjt_num">
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="과제고유번호 입력"
                  v-model="pjt_num"
                  v-on:keyup.enter="projectNumberSearch"
                />
                <button type="button" id="" @click="projectNumberSearch">
                  <span class="material-icons-outlined"> search </span>
                </button>
              </label>
            </td>
          </tr>
          <tr>
            <th>책임연구자 / 과제명 / 수행기관</th>
            <td>
              <label class="input-wrapper search_input">
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="책임연구자 입력"
                  v-model="projectManager"
                  v-on:keyup.enter="projectSearchResult"
                />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="과제명 입력"
                  v-model="projectTitleKr"
                  v-on:keyup.enter="projectSearchResult"
                />
                <input
                  type="text"
                  id=""
                  class=""
                  placeholder="수행기관 입력"
                  v-model="projectLeadAgency"
                  v-on:keyup.enter="projectSearchResult"
                />
                <button type="button" id="" @click="projectSearchResult">
                  <span class="material-icons-outlined"> search </span>
                </button>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br />
  <!-- 검색 구역 끝 -->

  <!-- 검색 결과구역 시작 -->
  <div class="info_area">
    <span>1000건 이하만 검색 가능합니다.</span>
  </div>
  <br />
  <div class="search_result">
    <div class="col-lg-12 control-section">
      <ejs-grid
        ref="grid"
        :dataSource="searchedProjectData"
        :toolbar="toolbarOptions"
        :allowSorting="true"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :allowSelection="true"
        :selectionSettings="selectionOptions"
        :rowSelected="dataRowSelected"
        :rowDeselected="rowDeselected"
        :actionComplete="actionComplete"
        :actionBegin="actionBegin"
      >
        <e-columns>
          <e-column type="checkbox" :allowFiltering="true" :allowSorting="true" width="60"></e-column>
          <e-column field="projectNo" headerText="번호" :disableHtmlEncode="false" width="60" textAlign="center"></e-column>
          <e-column field="projectNumber" headerText="과제고유번호" :disableHtmlEncode="false" width="150" textAlign="center"></e-column>
          <e-column field="projectManager" headerText="책임연구자" :disableHtmlEncode="false" width="120" textAlign="center"></e-column>
          <e-column field="projectTitleKr" headerText="과제명" :disableHtmlEncode="false" width="400" textAlign="center"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
  <br />

  <!-- 검색 결과 구역 끝 -->

  <!-- 버튼 구역 -->
  <div class="btnArea">
    <b-button class="mx-1" variant="primary" @click="goBack()">목록</b-button>
    <b-button type="button" v-on:click="saveSelected()" class="mx-1" variant="primary">저장</b-button>
  </div>
  <!-- 검색 구역 끝 -->
</template>

<style scoped>
.lab_info_area > input:nth-child(1) {
  width: 150px !important;
}
.lab_info_area > input {
  width: 300px;
}
.projectSearch {
  margin: 20px 0;
}
.input-wrapper {
  display: flex;
}
.input-wrapper input {
  width: 25em;
  border: 2px solid var(--wf-primary-color);
  padding: 0.5em 1em;
}
.input-wrapper input::placeholder {
  color: var(--wf-gray-color);
}
.input-wrapper button {
  background-color: var(--wf-primary-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  padding: 0.3em;
}
.input-wrapper button span {
  color: #fff;
  font-size: 28px;
}
.search_input > input {
  margin-right: 10px;
}
</style>
