/**
 * TODO: 프로젝트 고유번호 파라미터로 받는 함수
 * TODO:고유번호로 프로젝트, 논문, 지재권 검색 함수 불러오기
 * TODO:가져온 프로젝트, 논문, 지재권 저장하기
 */

import { searchPjt, searchPaperAll, searchPatentAll } from './ntis'
import { insertPjtTable, insertPaperTable, insertPatentTable } from './ntisSave'

// ******************** NTIS 검색 후 저장 함수  ********************
export const searchSave = async (pjtNumber, labId) => {
  // 프로젝트 검색
  const pjtData = await searchPjt(pjtNumber)

  // 과제 고유번호로 논문, 지재권 검색
  const select = 'TI03'
  const { LIST: LISTPaper } = await searchPaperAll(select === 'TI03' ? pjtNumber : { addQuery: `${select}=${pjtNumber}` })
  const { LIST: LISTPatent } = await searchPatentAll(select === 'TI03' ? pjtNumber : { addQuery: `${select}=${pjtNumber}` })

  // 연구실 번호 객체로 넣어주기. 그래야 insertPjtTable의 연구실 번호에 값 들어감.
  const labIdData = { labNo: labId }

  // 저장 함수 호출
  insertPjtTable(pjtData, labIdData)
  insertPaperTable(LISTPaper, labId)
  insertPatentTable(LISTPatent, labId)
  return 1
}
